<template>
  <v-container>
    <v-row>
      <v-col>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-card
              elevation="10"
              max-width="1000"
              class="ma-auto"
          >
            <v-card-title>
              Edit essay
            </v-card-title>
            <v-card-text>
              <v-text-field
                  label="Title"
                  v-model="essay_data.title"
                  solo
                  :rules="rules.title"
                  required
              ></v-text-field>

              <v-autocomplete
                  label="Domain"
                  v-model="domains_selected"
                  :items="domains"
                  @change="fetchCategories();fetchTypes();"
                  clearable
                  :rules="rules.domain"
                  solo
              ></v-autocomplete>

              <v-combobox
                  label="Categories"
                  v-model="categories_selected"
                  :items="categories"
                  clearable
                  multiple
                  chips
                  deletable-chips
                  small-chips
                  solo
              ></v-combobox>

              <v-combobox
                  label="Types"
                  v-model="types_selected"
                  :items="types"
                  clearable
                  multiple
                  chips
                  deletable-chips
                  small-chips
                  solo
              ></v-combobox>

              <v-text-field
                  label="Comment"
                  v-model="comment"
                  solo
              ></v-text-field>

              <v-checkbox
                  class="mt-0 mb-4"
                  v-model="hasBibliography"
                  :label="`Bibliography`"
              ></v-checkbox>

              <!-- <VueEditor v-model="essay_data.text"></VueEditor> -->

              <div v-if="essay_data.non_eng !== 0 && essay_data.language === 'english'" style="color:rgba(0, 0, 0, 0.87);font-size:1rem;font-weight:500;margin-bottom:5px;">
                Count of strange symbols: {{ essay_data.non_eng }}
              </div>
              <div style="color:rgba(0, 0, 0, 0.87);font-size:1rem;font-weight:500;margin-bottom:5px;">Language: {{ essay_data.language }}</div>
              <div style="color:rgba(0, 0, 0, 0.87);font-size:1rem;font-weight:500;margin-bottom:25px;">Source: {{ essay_data.essay_source }}</div>

              <div class="ckeditor">
                <editor v-model="essay_data.text"
                    api-key="cn6nlmqputqtjuaylp018wx6rae7yycx4ek20gzhk6iwwjb5"
                    :init="{
                       content_css: '/tinymce.css',
                       height: 500,
                       menubar: 'edit insert view format table tools',
                       toolbar: 'undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | numlist bullist | outdent indent | sentencecase uppercase lowercase',
                       plugins: [
                         'advlist autolink lists link image charmap print preview anchor',
                         'searchreplace visualblocks code fullscreen',
                         'insertdatetime media table paste code help wordcount uppercase lowercase sentencecase'
                       ],
                       setup(editor) {
                          editor.ui.registry.addButton('uppercase', {
                            text: 'AA',
                            tooltip: 'uppercase text',
                            onAction() {
                              const selectedText = editor.selection.getContent({ format: 'text' });
                              const uppercasedText = selectedText.toUpperCase();
                              editor.selection.setContent(uppercasedText);
                            }
                          });
                          editor.ui.registry.addButton('lowercase', {
                            text: 'aa',
                            tooltip: 'lowercase text',
                            onAction() {
                              const selectedText = editor.selection.getContent({ format: 'text' });
                              const uppercasedText = selectedText.toLowerCase();
                              editor.selection.setContent(uppercasedText);
                            }
                          });

                          editor.ui.registry.addButton('sentencecase', {
                            text: 'Aa',
                            tooltip: 'sentence case text',
                            onAction() {
                              editor.execCommand('sentenceCaseCommand');
                            }
                          });

                          editor.addCommand('sentenceCaseCommand', function() {
                            const selectedText = editor.selection.getContent({ format: 'text' });
                            const sentenceCasedText = selectedText.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function(c) {
                              return c.toUpperCase();
                            });
                            editor.selection.setContent(sentenceCasedText);
                          });
                        }
                    }"
                />
                <ckeditor :editor="editor" v-model="essay_data.text" :config="editorConfig"></ckeditor>
              </div>

              <div v-if="essay_data.spelling !== null && Object.keys(essay_data.spelling).length > 0">
                <button class="spell-button" :class="{ active: showGrammarCheck }" type="button" @click="showGrammarCheck = !showGrammarCheck">Show grammar check <i></i></button>
                <div class="spell-wrapper" v-if="showGrammarCheck">
                  <div class="spell-block" v-for="(sentence, index) in essay_data.spelling" :key="index">
                    <strong class="spell-title" @click="toggleAccordion(index)">{{ index }}</strong>
                    <ul v-if="activeIndex === index">
                      <li class="spell-word" v-for="word in sentence" :key="word">{{ word }}</li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="text-center pt-4 pb-4">
                <v-btn outlined color="primary" @click="preview = !preview">{{ preview ? 'Hide' : 'Show' }} Preview
                </v-btn>
              </div>

              <div v-if="preview" v-html="essay_data.text" class="pa-4"></div>
              <v-alert
                  v-if="alert.show"
                  text
                  :type="alert.type"
                  dismissible
              >
                {{ alert.message }}
              </v-alert>
            </v-card-text>
            <v-card-actions>
              <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="mr-4"
                  @click="updateEssay"
                  :loading="updateLoading"
                  text
              >
                <v-icon left>
                  mdi-content-save-outline
                </v-icon>
                Update
              </v-btn>
              <Blacklist v-if="essay_data.in_black_list === null" type="default"
                         :id="idEssay"></Blacklist>
              <v-btn v-else color="error"
                     class="mr-4" text
                     @click="resetBlacklist"
              >
                <v-icon left>
                  mdi-cancel
                </v-icon>
                Reset Blacklist
              </v-btn>
              <v-btn color="info"
                     class="mr-4" text
                     @click="revertEssay"
              >
                <v-icon left>
                  mdi-keyboard-return
                </v-icon>
                Revert
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import {VueEditor} from "vue2-editor";
import axios from "axios";
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Blacklist from "@/components/Common/Blacklist";
import Editor from '@tinymce/tinymce-vue';

export default {
  name: "Edit",
  components: {
    // VueEditor,
    Blacklist,
    'editor': Editor
  },
  data() {
    return {
      hasBibliography : false,
      showGrammarCheck: false,
      activeIndex: null,
      editor: Editor,
      interface_settings: {},
      startupMode: 'source',
      // editorData: '<p>Content of the editor.</p>',
      editorConfig: {
          // toolbar: [ 'bold', 'italic', '|', 'undo', 'redo' ]
          // toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
          heading: {
              options: [
                  { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                  { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                  { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                  { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                  { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                  { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                  { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' },
              ]
          }
      },
      alert: {
        show: false,
        type: 'success',
        message: ''
      },
      updateLoading: false,
      preview: false,
      valid: true,
      domains: [],
      domains_selected: undefined,
      categories: [],
      types: [],
      categories_selected: undefined,
      types_selected: undefined,
      comment: undefined,
      essay_data: {
        title: undefined,
        content: undefined,
        non_eng: 0,
        essay_source: null,
        language: null,
        spelling: null
      },
      rules: {
        title: [
          v => !!v || 'Title is required',
          v => (v && v.length <= 149) || 'Title must be less than 149 characters',
        ],
        domain: [
          v => !!v || 'Domain is required',
        ]
      },
    }
  },
  methods: {
    toggleAccordion(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
    updateEssay() {
      let self = this

      if (!this.$refs.form.validate()) {
        return
      }

      self.$store.state.loading = true
      self.updateLoading = true

      let url = `${self.$store.state.server_url}/essays/${self.$route.params.id}`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        title: self.essay_data.title,
        comment: self.comment,
        text: self.essay_data.text,
        domain: self.domains_selected,
        categories: self.categories_selected,
        types: self.types_selected,
        hasBibliography: self.hasBibliography
      }

      axios.put(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then(() => {
            self.alert.show = true
            self.alert.type = 'success'
            self.alert.message = 'Essay successfully updated!'
          })
          .finally(() => {
            self.updateLoading = false
            self.$store.state.loading = false
          })
    },
    resetBlacklist(){
      let self = this

      self.blacklist_dialog = false

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/essays/${self.$route.params.id}/blacklist_reset`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {}

      axios.post(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then(() => {
            self.alert.show = true
            self.alert.type = 'success'
            self.alert.message = 'Essay successfully reset blacklisted!'
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    revertEssay(){
      let self = this

      let url = `${self.$store.state.server_url}/essays/${self.$route.params.id}/revert`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {}

      axios.post(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then(() => {
            self.alert.show = true
            self.alert.type = 'success'
            self.alert.message = 'Essay successfully revert!'
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    fetchEssay() {
      let self = this

      self.$store.state.loading = true;

      let url = `${self.$store.state.server_url}/essays/${self.$route.params.id}/edit`

      let config = {
        params: {
          reserved: self.$route.query.reserved
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.essay_data = response.data.data.essay;
            self.essay_data.spelling = self.essay_data.spelling !== undefined && self.essay_data.spelling !== null ? JSON.parse(self.essay_data.spelling) : null;

            self.domains_selected = response.data.data.cf.domain
            self.categories_selected = response.data.data.cf.categories
            self.types_selected = response.data.data.cf.types
            self.comment = response.data.data.cf.comment
            self.hasBibliography = response.data.data.cf.hasBibliography ?? false

            self.fetchCategories()
            self.fetchTypes()
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    fetchDomains() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/custom-fields/get-domains`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.domains = response.data.domains
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    fetchCategories() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/custom-fields/get-categories`

      let config = {
        params: {
          domain: self.domains_selected
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.categories = response.data.categories
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    fetchTypes() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/custom-fields/get-types`

      let config = {
        params: {
          domain: self.domains_selected
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            console.log(response.data.types);
            self.types = response.data.types
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    checkEditorTheme(){
      setTimeout(function(){

        const tox_box = document.querySelector('.tox.tox-silver-sink.tox-tinymce-aux');
        if(tox_box){
          if(document.querySelector('#app.theme--dark') !== null){
            tox_box.classList.remove('theme-box--light');
            tox_box.classList.add('theme-box--dark');
          }else{
            tox_box.classList.remove('theme-box--dark');
            tox_box.classList.add('theme-box--light');
          }
        }
      }, 1000);
    }
  },
  computed: {
    idEssay() {
      return this.$route.params.id
    }
  },
  created() {
    this.fetchDomains()
    this.fetchEssay()
    this.checkEditorTheme()
  }
}
</script>

<style>
.ql-editor p {
  margin-bottom: 15px !important;
}

.theme--dark .ck-toolbar{
  filter: invert(.9);
}

.theme--dark .ck-editor__main{
  filter: invert(.9);
  color: black;
}
.theme--dark .tox .tox-edit-area__iframe{
  filter: invert(.9);
  background-color: #ccc;
}
.theme--dark .tox .tox-toolbar,
.theme--dark .tox .tox-toolbar__overflow,
.theme--dark .tox .tox-toolbar__primary,
.theme--dark .tox .tox-menubar,
.theme-box--dark.tox .tox-menu,
.theme--dark .tox .tox-statusbar,
.theme-box--dark.tox .tox-dialog__header,
.theme-box--dark.tox .tox-dialog{
  background-color: #363636;
}
.theme-box--dark.tox .tox-listboxfield .tox-listbox--select,
.theme-box--dark.tox .tox-textarea,
.theme-box--dark.tox .tox-textfield,
.theme-box--dark.tox .tox-toolbar-textfield,
.theme-box--dark.tox .tox-dialog__footer{
  background: #424242;
  color: rgba(255, 255, 255, 0.7);
  border-color: #777;
}
.theme-box--dark.tox .tox-button--naked[disabled]{
  background: #121212;
}
.theme--dark .tox .tox-menubar+.tox-toolbar,
.theme--dark .tox .tox-menubar+.tox-toolbar-overlord .tox-toolbar__primary,
.theme--dark .tox:not([dir=rtl]) .tox-toolbar__group:not(:last-of-type),
.theme--dark .tox .tox-statusbar{
  border-color: #777;
}
.theme--dark .tox .tox-edit-area__iframe,
.theme--dark .tox .tox-edit-area__iframe p,
.theme--dark .tox .tox-statusbar a,
.theme--dark .tox .tox-statusbar__path-item,
.theme--dark .tox .tox-statusbar__wordcount,
.theme--dark .tox .tox-mbtn,
.theme--dark .tox .tox-tbtn,
.theme-box--dark.tox .tox-dialog__header,
.theme-box--dark.tox .tox-collection__item-label{
  color: rgba(255, 255, 255, 0.7);
}
.theme--dark .tox .tox-toolbar__primary svg,
.theme--dark .tox .tox-statusbar__resize-handle svg,
.theme-box--dark.tox .tox-collection__item-checkmark svg,
.theme-box--dark.tox .tox-collection__item-caret svg,
.theme-box--dark.tox .tox-collection__item-icon svg,
.theme-box--dark.tox .tox-button--icon .tox-icon svg,
.theme-box--dark.tox .tox-button.tox-button--icon .tox-icon svg,
.theme-box--dark.tox .tox-button.tox-button--secondary.tox-button--icon .tox-icon svg{
  fill: rgba(255, 255, 255, 0.7);
}
.theme--dark .tox-tinymce{
  border: 1px solid #121212;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.theme-box--dark.tox .tox-collection__item.tox-collection__item--active .tox-collection__item-label{
  color: #222f3e;
}
.theme-box--dark.tox .tox-collection__item.tox-collection__item--active .tox-collection__item-caret svg,
.theme-box--dark.tox .tox-collection__item.tox-collection__item--active .tox-collection__item-icon svg{
  fill: #222f3e;
}
.theme-box--dark.tox .tox-collection--list .tox-collection__item--enabled{
  background: #222f3e;
}
.spell-button{
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 500;
  margin-top: 25px;
  position: relative;
}
.spell-button i:after{
  content: "\F035D";
  font: normal normal normal 24px/1 "Material Design Icons";
  position: relative;
  top: 3px;
  transition: all .3s;
}
.spell-button i{
  display: inline-flex;
  transition: all .3s;
}
.spell-button.active i{
  transform: rotate(180deg);
}
.spell-button.active i:after{
  top: 0;
}
.spell-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.spell-block{
  display: flex;
  flex-direction: column;
  width: 33.33%;
}
.spell-block ul{
  padding: 0;
  list-style: none;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}
.spell-title{
  cursor: pointer;
}
.spell-word{
  width: 33.33%;
}
</style>
