import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import router from "@/plugins/router";
import store from "@/store/index";
import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );

Vue.config.productionTip = false

Vue.use(VueRouter);

new Vue({
    vuetify,
    router,
    store,
    render: h => h(App)
}).$mount('#app')
