<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <v-text-field
                  solo
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>

            <v-data-table
                :search="search"
                :headers="headers"
                :items="items"
                :items-per-page="10"
                class="elevation-1"
            >
              <template v-slot:item.action="{ item }">
                <v-btn @click="goTo(`/admin/users/edit/${item.id}`)" icon small>
                  <v-icon small>mdi-square-edit-outline</v-icon>
                </v-btn>
                <v-btn @click="goTo(`/admin/statistic/${item.id}`)" icon small>
                  <v-icon small>mdi-chart-timeline-variant</v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-card>
          <v-fab-transition>
            <v-btn
                color="pink"
                dark
                fixed
                bottom
                right
                fab
                @click="goTo(`/admin/users/create`)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Index",
  data() {
    return {
      search: '',
      headers: [
        {text: 'ID', value: 'id', width: '70', align: 'center'},
        {text: 'Email', value: 'email'},
        {text: 'Action', value: 'action', width: '110', align: 'center', sortable: false},
      ],
      items: []
    }
  },
  methods: {
    goTo(to) {
      this.$router.push(to)
    },
    fetchUsers() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/admin/users`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.items = response.data.users
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    }
  },
  created() {
    this.fetchUsers()
  }
}
</script>

<style scoped>

</style>