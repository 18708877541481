<template>
  <div class="d-inline-block">
    <v-btn v-if="type === 'default'"
           color="error"
           class="mr-4"
           @click="blacklist_dialog = true"
           text
    >
      <v-icon left>
        mdi-cancel
      </v-icon>
      Send to blacklist
    </v-btn>
    <v-btn
        v-else icon small color="error"
        @click="blacklist_dialog = true"
    >
      <v-icon small>mdi-delete-outline</v-icon>
    </v-btn>
    <v-dialog
        v-model="blacklist_dialog"
        max-width="300px"
    >
      <v-card>
        <v-card-title>
        </v-card-title>
        <v-card-text>
          <v-text-field
              label="What reason?"
              v-model="reason_blacklist"
              solo
              required
              hide-details
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
              color="primary"
              text
              @click="sendToBlacklist"
              :disabled="reason_blacklist.length < 5"
          >
            Send
          </v-btn>
          <v-btn
              color="error"
              text
              @click="blacklist_dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Blacklist",

  props: ['id', 'type'],

  data: () => ({
    reason_blacklist: '',
    blacklist_dialog: false,
  }),

  methods: {
    sendToBlacklist() {
      let self = this

      self.blacklist_dialog = false

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/essays/${self.id}/blacklist`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        reason: self.reason_blacklist
      }

      axios.post(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((r) => {
            if(r.data && r.data.status){
              alert('Added to blacklist');
            }
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
  }
}
</script>

<style scoped>

</style>