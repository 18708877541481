import Vue from 'vue'
import Vuex from 'vuex';
import auth from './modules/auth/auth'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        auth
    },
    state: {
        beta: true,
        sheet: false,
        sheet_message: "An error has occurred, we are working on a fix.",
        enable_test_data: false,
        count: 0,
        redirect_to_after_login: '',
        loading: false,
        loading_indeterminate: true,
        server_url: 'https://essayhub-backend.prokit.me/api'
        // server_url: 'http://localhost:80/api'
    }
});

export default store