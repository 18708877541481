<template>
  <div class="d-inline-block">
    <v-btn @click="dialog = true" icon>
      <v-icon>mdi-cog</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" persistent scrollable max-width="600px">
      <v-card>
        <v-tabs
            v-model="tab"
            centered
        >
          <v-tab
              :key="1"
          >
            Main
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-tabs-items v-model="tab">
                  <v-tab-item
                      :key="1"
                  >
                    <v-container fluid>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                              label="Domain"
                              v-model="domains_selected"
                              :items="domains"
                              clearable
                              solo
                          ></v-autocomplete>
                          <v-textarea
                              label="Categories"
                              v-model="categories"
                              solo
                              name="input-7-4"
                              hint="Separate by new line"
                          ></v-textarea>
                          <v-btn
                              text
                              color="primary"
                              :disabled="uploadDisabled"
                              @click="uploadCategories"
                          >Upload
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              :disabled="!domains_selected"
                              @click="downloadCategories"
                          >Download
                          </v-btn>
                          <v-textarea
                              label="Types"
                              v-model="types"
                              solo
                              name="input-7-5"
                              hint="Separate by new line"
                              style="margin-top:30px"
                          ></v-textarea>
                          <v-btn
                              text
                              color="primary"
                              :disabled="uploadTypesDisabled"
                              @click="uploadTypes"
                          >Upload
                          </v-btn>
                          <v-btn
                              text
                              color="primary"
                              :disabled="!domains_selected"
                              @click="downloadTypes"
                          >Download
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="saveData"
              :disabled="dialogButtonDisable"
          >
            Save
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Settings",

  data: () => ({
    categories: '',
    types: '',
    domains_selected: undefined,
    domains: [],
    dialog: false,
    tab: 1,
  }),

  methods: {
    uploadCategories() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/custom-fields/upload-categories`

      let data = {
        domain: self.domains_selected,
        categories: self.categories.split("\n")
      }

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.post(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            if (!response.data.success) {
              alert(response.data.msg)
            }
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    uploadTypes() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/custom-fields/upload-types`

      let data = {
        domain: self.domains_selected,
        types: self.types.split("\n"),
      }

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.post(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            if (!response.data.success) {
              alert(response.data.msg)
            }
          })
          .finally(() => {
            self.$store.state.loading = false;
            alert("Data inserted")
          })
    },
    downloadCategories() {
      window.open(`${this.$store.state.server_url}/main/custom-fields/download-categories/${this.domains_selected}`, '_blank')
    },
    downloadTypes() {
      window.open(`${this.$store.state.server_url}/main/custom-fields/download-types/${this.domains_selected}`, '_blank')
    },
    fetchDomains() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/custom-fields/get-domains`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.domains = response.data.domains
            self.$store.state.domains = [];
            for (let domainsKey in self.domains) {
              self.$store.state.domains.push(self.domains[domainsKey].value);
            }
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    saveData() {

    },
  },

  computed: {
    uploadDisabled(){
      return !this.domains_selected || this.categories.length === 0
    },
    uploadTypesDisabled(){
      return !this.domains_selected || this.types.length === 0
    },
    dialogButtonDisable() {
      return true
    }
  },

  created() {
    this.fetchDomains()
  }
}
</script>

<style scoped>

</style>