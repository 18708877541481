<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-card
              elevation="10"
              max-width="800"
              class="ma-auto"
          >
            <v-card-text>

              <v-text-field
                  solo
                  v-model="user_data.name"
                  :rules="rules.name"
                  label="Name"
                  required
              ></v-text-field>

              <v-text-field
                  solo
                  v-model="user_data.email"
                  :rules="rules.email"
                  label="E-mail"
                  required
              ></v-text-field>

              <v-text-field
                  solo
                  v-model="user_data.password"
                  :rules="rules.password"
                  label="Password"
                  required
              ></v-text-field>

              <v-autocomplete
                  label="Sources"
                  v-model="essay_sources_selected"
                  :items="essay_sources"
                  :rules="rules.essay_sources"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                  solo
                  required
              ></v-autocomplete>

              <v-autocomplete
                  label="Group of sources"
                  v-model="essay_alt_sources_selected"
                  :items="essay_alt_sources"
                  :rules="rules.essay_alt_sources"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                  solo
                  required
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="mr-4"
                  @click="createUser"
                  text
              >
                Create
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
          v-model="alert.on"
          persistent
          max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{alert.title}}
          </v-card-title>
          <v-card-text
            v-html="alert.text"
          ></v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="green darken-1"
                text
                @click="toUsers"
            >
              Done
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Create",
  data() {
    return {
      valid: true,
      rules: {
        name: [
          v => !!v || 'Name is required',
          v => (v && v.length > 4) || 'The name must be at least 5 characters.',
          v => (v && v.length <= 10) || 'Name must be less than 10 characters',
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        password: [
          v => !!v || 'Password is required',
          v => (v && v.length > 5) || 'The password must be at least 6 characters.',
        ],
        essay_sources: [
          v => !!v || 'Required',
          v => (v && v.length > 0) || 'Required',
        ],
        essay_alt_sources: [
          v => !!v || 'Required',
          v => (v && v.length > 0) || 'Required',
        ],
      },
      user_data: {
        email: undefined,
        name: undefined,
        password: undefined
      },
      essay_sources_selected: undefined,
      essay_sources: [],
      essay_alt_sources_selected: undefined,
      essay_alt_sources: [],
      alert: {
        on : false,
        title : '',
        text : '',
      }
    }
  },
  methods: {
    createUser() {
      if (!this.$refs.form.validate()) {
        return
      }

      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/admin/users`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        name: self.user_data.name,
        email: self.user_data.email,
        password: self.user_data.password,
        essay_sources_selected: self.essay_sources_selected,
        essay_alt_sources_selected: self.essay_alt_sources_selected,
      }

      axios.post(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }

            let alertText = '';
            if (err.response.data.message) {
              alertText = alertText + err.response.data.message + "\n";
            }

            if (err.response.data.errors) {
              for (let errorsKey in err.response.data.errors) {
                alertText = alertText + "\t" + err.response.data.errors[errorsKey] + "" + "\n";
              }
            }

            if(alertText.length === 0){
              alertText = JSON.stringify(err.response);
            }

            alert(alertText);
          })
          .then((response) => {
            console.log(response)
            if (response.data && response.data.success) {

              self.alert.on = true;
              self.alert.title = 'User "' + self.user_data.name + '" created!';

              self.alert.text =
                  'Email: <b>' + self.user_data.email + '</b><br>' +
                  'Password: <b>' + self.user_data.password + '</b>';
            }
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    fetchEssaySources() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/main/sources`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            let data = response.data

            self.essay_alt_sources = data.essay_alt_sources
            self.essay_sources = data.essay_sources
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    toUsers() {
      this.$router.push('/admin/users/index');
    },
  },
  created() {
    this.fetchEssaySources()
  },
}
</script>

<style scoped>

</style>