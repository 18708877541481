import Vue from 'vue'
import VueRouter from 'vue-router'

import auth from '@/middleware/auth'
import store from '@/store'
import middlewarePipeline from '@/middleware/pipeline'

/**
 * Common
 */
import HomeIndex from '@/components/Home'
import LoginFormIndex from '@/components/LoginForm/Index';
import PageNotFound from "@/components/NotFound/PageNotFound";

/**
 * Admin
 */

import AdminUserHome from '@/components/Admin/User/Home'
import AdminUserIndex from '@/components/Admin/User/Index'
import AdminUserEdit from '@/components/Admin/User/Edit'
import AdminUserCreate from '@/components/Admin/User/Create'

import AdminStatisticHome from '@/components/Admin/Statistic/Home'
import AdminStatisticIndex from '@/components/Admin/Statistic/Index'
import AdminStatisticShow from '@/components/Admin/Statistic/Show'

/**
 * Essays
 */

import EssayEdit from '@/components/Essay/Edit'

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: __dirname,
    routes: [
        {
            path: '/login',
            name: 'login',
            component: LoginFormIndex,
        },
        {
            path: '/',
            name: 'home',
            component: HomeIndex,
            meta: {
                middleware: [
                    auth
                ],
            },
        },
        {
            path: '/essays/edit/:id',
            name: 'essays.edit',
            component: EssayEdit,
            meta: {
                middleware: [
                    auth
                ],
            },
        },
        {
            path: '/admin/users',
            name: 'admin.users',
            component: AdminUserHome,
            meta: {
                middleware: [
                    auth
                ],
            },
            children: [
                {
                    name: 'admin.users.index',
                    path: 'index',
                    component: AdminUserIndex
                },
                {
                    name: 'admin.users.edit',
                    path: 'edit/:id',
                    component: AdminUserEdit
                },
                {
                    name: 'admin.users.create',
                    path: 'create',
                    component: AdminUserCreate
                },
            ]
        },
        {
            path: '/admin/statistic',
            name: 'admin.statistic',
            component: AdminStatisticHome,
            meta: {
                middleware: [
                    auth
                ],
            },
            children: [
                {
                    name: 'admin.statistic.index',
                    path: '',
                    component: AdminStatisticIndex
                },
                {
                    name: 'admin.statistic.show',
                    path: ':id',
                    component: AdminStatisticShow
                },
            ]
        },
        {
            path: '/404',
            name: '404',
            component: PageNotFound,
            meta: {
                middleware: [
                    auth
                ],
            },
        },
        {path: "*", component: PageNotFound}
    ]
})

router.beforeEach((to, from, next) => {
    if (!to.meta.middleware) {
        return next()
    }
    const middleware = to.meta.middleware
    const context = {
        to,
        from,
        next,
        store
    }
    return middleware[0]({
        ...context,
        next: middlewarePipeline(context, middleware, 1)
    })
})

export default router