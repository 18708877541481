<template>
  <div id="app">
    <v-app v-if="!is_authenticated">
      <router-view></router-view>
    </v-app>
    <v-app v-if="is_authenticated">
      <v-navigation-drawer
          v-model="drawer"
          :clipped="$vuetify.breakpoint.lgAndUp"
          app
          floating
          mini-variant
          expand-on-hover
      >
        <v-list dense>
          <v-list-item v-if="menu_levels.length !== 0" @click="menuBack">
            <v-list-item-action>
              <v-icon>mdi-chevron-left</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Back</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <div v-for="item in menu_items" :key="item.link">
            <v-list-item v-if="item.child === undefined" link :to="{ name: item.link }">
              <v-list-item-action>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-else @click="updateMenu(item.child)">
              <v-list-item-action>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title v-html="item.title"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-navigation-drawer>

      <v-app-bar
          :clipped-left="$vuetify.breakpoint.lgAndUp"
          app
          hide-on-scroll
      >
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-progress-linear
            :active="$store.state.loading"
            :indeterminate="$store.state.loading_indeterminate"
            absolute
            bottom
            color="primary"
        ></v-progress-linear>

        <v-spacer></v-spacer>

        <v-btn @click="goTo('/admin/statistic')" icon>
          <v-icon>mdi-chart-timeline-variant</v-icon>
        </v-btn>

        <v-btn @click="toggleDarkMode" icon>
          <v-icon>mdi-desk-lamp</v-icon>
        </v-btn>

        <Settings></Settings>

        <v-menu
            transition="slide-x-transition"
            left
            bottom
            :close-on-content-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                icon
                v-bind="attrs"
                v-on="on"
            >
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>

      <v-main>
        <router-view></router-view>
        <br>
        <br>
      </v-main>
    </v-app>
  </div>
</template>

<script>
import Settings from "@/components/Common/Settings";
import axios from "axios";

export default {
  name: 'App',

  components: {Settings},

  data: () => ({
    drawer: true,
    interface_settings: {},
    dialog_settings: false,
    menu_items_base: [
      {
        link: "home",
        icon: "mdi-home",
        title: "Home"
      },
      {
        link: "admin.users.index",
        icon: "mdi-account-multiple",
        title: "Users"
      },
    ],
    menu_levels: [],
    menu_items: [],
    menu: false,
  }),

  computed: {
    is_authenticated() {
      return this.$store.getters.isAuthenticated
    },
  },

  methods: {
    goTo(to) {
      this.$router.push(to)
    },
    logout() {
      let self = this;

      self.$store.dispatch('logout', function () {
        self.$router.push('/login');
      })
    },
    toggleDarkMode() {

      const tox_box = document.querySelector('.tox.tox-silver-sink.tox-tinymce-aux');
      if(tox_box){
        if(!this.interface_settings.dark_mode){
          tox_box.classList.remove('theme-box--light');
          tox_box.classList.add('theme-box--dark');
        }else{
          tox_box.classList.remove('theme-box--dark');
          tox_box.classList.add('theme-box--light');
        }
      }
      this.interface_settings.dark_mode = !this.interface_settings.dark_mode
      this.saveInterfaceSettings()
    },
    saveInterfaceSettings() {
      this.dialog_settings = false
      localStorage.setItem('interface_settings', JSON.stringify(this.interface_settings))

      this.$vuetify.theme.dark = this.interface_settings.dark_mode
    },
    updateMenu(value) {
      this.menu_levels.push(this.menu_items)
      this.menu_items = value
    },
    menuBack() {
      this.menu_items = this.menu_levels.pop()
    },
    checkAuth() {
      let self = this

      let url = `${self.$store.state.server_url}/check-auth`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.logout()
            }
          })
    }
  },

  mounted() {
    if (localStorage.getItem('interface_settings')) {
      try {
        this.interface_settings = JSON.parse(localStorage.getItem('interface_settings'))
        this.$vuetify.theme.dark = this.interface_settings.dark_mode
      } catch (e) {
        localStorage.removeItem('interface_settings')
      }
    }
  },

  created() {
    this.menu_items = this.menu_items_base
    // this.checkAuth()
  }
};
</script>
