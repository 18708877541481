<template>
  <v-main>
    <v-container
        class="fill-height"
        fluid
    >
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            cols="12"
            md="6"
        >
          <v-alert
              icon="mdi-shield-lock-outline"
              prominent
              text
              type="info"
          >
            <v-row align="center">
              <v-col class="grow">
                <h3 class="headline">404 Page not found</h3>
                Nunc nonummy metus. Nunc interdum lacus sit amet orci. Nullam dictum felis eu pede mollis pretium. Cras id dui.
              </v-col>
              <v-col class="shrink">
                <v-btn color="primary" @click="go_to_login">Okay</v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "PageNotFound",
  methods: {
    go_to_login() {
      let self = this;
      self.$router.push('/login');
    },
  }
}
</script>

<style scoped>

</style>