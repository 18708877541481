<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
          <v-card
              elevation="10"
              max-width="800"
              class="ma-auto"
          >
            <v-card-text>

              <v-text-field
                  solo
                  v-model="user_data.name"
                  :rules="rules.name"
                  label="Name"
                  required
              ></v-text-field>

              <v-text-field
                  solo
                  v-model="user_data.email"
                  :rules="rules.email"
                  label="E-mail"
                  required
              ></v-text-field>

              <v-text-field
                  solo
                  v-model="user_data.password"
                  :rules="rules.password"
                  label="Password"
              ></v-text-field>

              <v-autocomplete
                  v-model="essay_sources_selected"
                  :items="essay_sources"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                  solo
              ></v-autocomplete>

              <v-autocomplete
                  v-model="essay_alt_sources_selected"
                  :items="essay_alt_sources"
                  chips
                  clearable
                  deletable-chips
                  multiple
                  small-chips
                  solo
              ></v-autocomplete>
            </v-card-text>
            <v-card-actions class="pa-4">
              <v-btn
                  :disabled="!valid"
                  color="primary"
                  class="mr-4"
                  @click="updateUserData"
                  text
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";

export default {
  name: "Edit",
  data() {
    return {
      valid: true,
      rules: {
        name: [
          v => !!v || 'Name is required',
          v => (v && v.length <= 100) || 'Name must be less than 10 characters',
        ],
        email: [
          v => !!v || 'E-mail is required',
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
      },
      user_data: {
        email: undefined,
        name: undefined,
        password: undefined
      },
      essay_sources_selected: undefined,
      essay_sources: [],
      essay_alt_sources_selected: undefined,
      essay_alt_sources: []
    }
  },
  methods: {
    goTo(to) {
      this.$router.push(to)
    },
    updateUserData() {
      if (!this.$refs.form.validate()) {
        return
      }

      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/admin/users/${self.$route.params.id}`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      let data = {
        name: self.user_data.name,
        email: self.user_data.email,
        password: self.user_data.password,
        essay_sources_selected: self.essay_sources_selected,
        essay_alt_sources_selected: self.essay_alt_sources_selected,
      }

      axios.put(url, data, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            console.log(response)
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    },
    fetchUserData() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/admin/users/${self.$route.params.id}/edit`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            let data = response.data

            self.user_data.name = data.user_data.name
            self.user_data.email = data.user_data.email
            self.essay_alt_sources = data.essay_alt_sources
            self.essay_alt_sources_selected = data.essay_alt_sources_selected
            self.essay_sources = data.essay_sources
            self.essay_sources_selected = data.essay_sources_selected
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    }
  },
  created() {
    this.fetchUserData()
  }
}
</script>

<style scoped>

</style>