<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card v-bind:class="'sort-by-'+sortBy">
          <v-card-title>
            <v-row>
              <v-col cols="12" v-if="isAdvancedSearch">
                <v-row>

                  <v-col cols="12" lg="12">
                    <v-select
                        :items="['Partial match', 'Exact match']"
                        v-model="searchType"
                        label="Search type (text only)"
                    ></v-select>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="6" lg="2">
                    <v-text-field type="number" v-model="advancedSearchRules.uniqueness.min" hint="min=0 max=100"
                                  label="Uniqueness (min)"></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6" lg="2">
                    <v-text-field type="number" v-model="advancedSearchRules.uniqueness.max" hint="min=0 max=100"
                                  label="Uniqueness (max)"></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="6" lg="2">
                    <v-text-field type="number" v-model="advancedSearchRules.word_count.min"
                                  label="Text word count (min)"></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="6" lg="2">
                    <v-text-field type="number" v-model="advancedSearchRules.word_count.max"
                                  label="Text word count (max)"></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="6" lg="2">
                    <v-text-field type="number" v-model="advancedSearchRules.length.min"
                                  label="Text length (min)"></v-text-field>
                  </v-col>

                  <v-col class="d-flex" cols="12" sm="6" lg="2">
                    <v-text-field type="number" v-model="advancedSearchRules.length.max"
                                  label="Text length (max)"></v-text-field>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                  class="d-flex justify-space-around align-center"
                  cols="12"
              >

                <v-text-field
                    solo
                    v-model="search"
                    label="Search"
                    single-line
                    hide-details
                    v-on:keyup.enter="fetchEssays"
                    v-on:click:append="fetchEssays"
                ></v-text-field>

                <v-btn
                    class="mx-0 mx-5"
                    fab
                    dark
                    small
                    color="primary"
                    v-on:click="fetchEssays"
                >
                  <v-icon dark>
                    mdi-magnify
                  </v-icon>
                </v-btn>

                <v-checkbox
                    v-model="isAdvancedSearch"
                    :label="`Advanced Search`"
                ></v-checkbox>
              </v-col>

            </v-row>
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="items"
              :items-per-page="10"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{
                'disable-items-per-page': false,
                'items-per-page-options': [10, 50, 100]
              }"
              class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn @click="goTo(`/essays/edit/${item.id}`, 'blank')" icon small>
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-btn>
              <Blacklist :id="item.id"></Blacklist>
              <!--              <v-btn @click="deleteEssay(item.id)" icon small color="error">-->
              <!--                <v-icon small>mdi-delete-outline</v-icon>-->
              <!--              </v-btn>-->
            </template>
            <template v-slot:item.excerpt="{ item }">
              <span v-html="item.excerpt"></span>
            </template>
            <template v-slot:item.title="{ item }">
              <span v-html="item.title"></span>
            </template>
          </v-data-table>
          <v-overlay
              :absolute="true"
              :value="loading"
          >
            <v-progress-circular
                :size="70"
                :width="7"
                color="primary"
                indeterminate
            ></v-progress-circular>
          </v-overlay>
        </v-card>
        <Export></Export>
      </v-col>
    </v-row>
    <v-row>
      <v-col></v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import Export from "@/components/Common/Export"
import Blacklist from "@/components/Common/Blacklist";

export default {
  name: 'HelloWorld',

  components: {Export, Blacklist},

  data: () => ({
    isAdvancedSearch: false,
    advancedSearchRules: {
      uniqueness: {
        min: undefined,
        max: undefined,
      },
      word_count: {
        min: undefined,
        max: undefined,
      },
      length: {
        min: undefined,
        max: undefined,
      },
    },
    search: '',
    page: 1,
    headers: [
      {text: 'Title', value: 'title', sortable: false, width: 200},
      {text: 'Excerpt', value: 'excerpt', sortable: false,},
      {text: 'Occurrences', value: 'occurrences', width: 120, align: 'center'},
      {text: 'Uniqueness', value: 'uniqueness', width: 115, align: 'center'},
      {text: 'Word count', value: 'word_count', width: 115, align: 'center'},
      {text: 'Length', value: 'length', width: 110, align: 'center'},
      {text: 'Language', value: 'language', width: 110, align: 'center'},
      {text: 'Actions', value: 'actions', width: 100, align: 'center', sortable: false},
    ],
    items: [],
    total: 0,
    options: {},
    loading: false,
    searchType: 'Partial match',
    sortBy: false
  }),

  watch: {
    options: {
      handler() {
        this.fetchEssays()
      },
      deep: true,
    },
  },

  methods: {
    goTo(to, target) {
      if (target && target === 'blank') {
        return window.open(to, '_blank')
      }
      this.$router.push(to)
    },
    fetchEssays() {
      let self = this

      if (self.loading) {
        console.log('Still loading')
        return '';
      }

      self.loading = true
      self.$store.state.loading = true
      self.sortBy = self.options.sortBy

      let url = `${self.$store.state.server_url}/essays`

      let config = {
        params: {
          search: self.search,
          page: self.options.page,
          itemsPerPage: self.options.itemsPerPage,
          sortBy: self.options.sortBy,
          sortDesc: self.options.sortDesc,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      if (self.isAdvancedSearch) {
        config.params.searchType = self.searchType === 'Exact match' ? 'exactMatch' : 'partialMatch';
        config.params.advancedSearchRules = self.advancedSearchRules;
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
            self.items = response.data.essays.hits
            self.total = response.data.essays.total
          })
          .finally(() => {
            self.loading = false
            self.$store.state.loading = false
          })
    }
  },

  created() {
    setTimeout(() => {
      this.fetchEssays()
    }, 100);
  }
}
</script>

<style>
.s-item {
  background-color: #ffff00bf;
  font-weight: normal;
}
.text-start {
  word-break: break-word;
}

</style>