<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" :md="history.reserved.is_data_fetched ? 3 : 12">
          <v-card
              :class="{'mx-auto my-12': !history.reserved.is_data_fetched}"
              max-width="374"
              elevation="15"
          >
            <v-card-title>{{ user_data.name }}</v-card-title>
            <v-card-text>
              <p>{{ user_data.email }}</p>
              <v-alert
                  text
                  type="success"
              >
                Reserved: {{ reserved }}
              </v-alert>
              <v-alert
                  text
                  type="error"
              >
                Blacklisted: {{ blacklisted }}
              </v-alert>
              <div class="text-center">
                <Export type="default" :user="userId"></Export>
                <v-btn
                    outlined
                    color="primary"
                    rounded
                    class="ml-2"
                    @click="dialog = true"
                >History <v-icon size="20" class="ml-2">mdi-chart-timeline-variant</v-icon></v-btn>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="9" v-if="history.reserved.is_data_fetched">
          <h3 class="text-h5 mb-3">Reserved</h3>

          <v-row>
            <v-col
                cols="12"
                md="3"
            >
              <v-text-field
                  label="Essay ID"
                  solo
                  placeholder="Essay ID"
                  v-model="search_reserved_id"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <v-text-field
                  label="Title"
                  solo
                  placeholder="Title"
                  v-model="search_reserved_title"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                md="3"
            >
              <v-select
                  solo
                  v-model="search_reserved_domain"
                  :items="this.$store.state.domains"
                  label="Domain"
              ></v-select>
            </v-col>

            <v-col
                cols="12"
                md="3"
            >
              <v-btn
                  color="primary"
                  block
                  class="mt-1"
                  v-on:click="fetchReservedEssays"
              >
                Search
              </v-btn>
            </v-col>

          </v-row>

          <v-data-table
              :headers="history.reserved.headers"
              :items="history.reserved.items"
              :items-per-page="10"
              :options.sync="options_reserved"
              :server-items-length="history.reserved.total"
              :footer-props="{
                'disable-items-per-page': false,
                'items-per-page-options': [10, 50, 100]
              }"
              class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn @click="goTo(`/essays/edit/${item.id}?reserved=1`, 'blank')" icon small>
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <h3 class="text-h5 mt-4">Blacklisted</h3>
          <v-data-table
              :headers="history.blacklisted.headers"
              :items="history.blacklisted.items"
              :items-per-page="10"
              :options.sync="options_blacklisted"
              :server-items-length="history.blacklisted.total"
              :footer-props="{
                'disable-items-per-page': false,
                'items-per-page-options': [10, 50, 100]
              }"
              class="elevation-1"
          >
            <template v-slot:item.actions="{ item }">
              <v-btn @click="goTo(`/essays/edit/${item.id}`, 'blank')" icon small>
                <v-icon small>mdi-square-edit-outline</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="dialog"
        max-width="300"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-dialog
              ref="dialog"
              v-model="dialog_dates"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  solo
                  label="Date range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dates"
                range
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="dialog_dates = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-card-text>
        <v-card-actions>
          <v-btn
              text
              color="primary"
              @click="fetchAllEssays"
              :disabled="isExportDisable"
          >Show
          </v-btn>
          <v-btn
              text
              @click="dialog = false"
          >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Export from "@/components/Common/Export"
import axios from "axios";

export default {
  name: "Home",
  data: () => ({
    dialog: false,
    dialog_dates: false,
    dates: [],
    history: {
      reserved: {
        items: [],
        total: 0,
        headers: [
          {text: 'Title', value: 'title'},
          {text: 'Updated At', value: 'updated_at', width: 200},
          {text: 'Actions', value: 'actions', width: 50, align: 'center', sortable: false},
        ],
        is_data_fetched: false
      },
      blacklisted: {
        items: [],
        total: 0,
        headers: [
          {text: 'Title', value: 'title'},
          {text: 'Reason', value: 'reason', width: 200},
          {text: 'Created At', value: 'created_at', width: 200},
          {text: 'Actions', value: 'actions', width: 50, align: 'center', sortable: false},
        ],
        is_data_fetched: false
      }
    },
    options_reserved: {
      page: 1
    },
    options_blacklisted: {
      page: 1
    },
    reserved: 0,
    blacklisted: 0,
    user_data: {},
    search_reserved_id: '',
    search_reserved_title: '',
    search_reserved_domain: '',
  }),

  components: {Export},

  watch: {
    options_reserved: {
      handler () {
        this.fetchEssays('reserved')
      },
      deep: true,
    },
    options_blacklisted: {
      handler () {
        this.fetchEssays('blacklisted')
      },
      deep: true,
    },
  },

  methods: {
    dateFormat(date){
      return (new Date(date)).toDateString()
    },
    goTo(to, target) {
      if(target && target === 'blank'){
        return window.open(to, '_blank')
      }
      this.$router.push(to)
    },
    fetchAllEssays(){
      this.fetchEssays('reserved')
      this.fetchEssays('blacklisted')
    },
    fetchReservedEssays(){
      this.fetchEssays('reserved')
    },
    fetchBlacklistedEssays(){
      this.fetchEssays('blacklisted')
    },
    fetchEssays(table){
      let self = this

      self.$store.state.loading = true

      let id = self.userId

      let url = `${self.$store.state.server_url}/admin/user-essays`

      let config = {
        params: {
          page: self[`options_${table}`].page,
          limit: self[`options_${table}`].itemsPerPage,
          date_from: self.dates[0],
          date_to: self.dates[1],
          user: id,
          type: table,
          search_reserved_id : self.search_reserved_id,
          search_reserved_title : self.search_reserved_title,
          search_reserved_domain : self.search_reserved_domain,
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.history[table].items = response.data.essays.hits
            self.history[table].total = response.data.essays.total
          })
          .finally(() => {
            self.$store.state.loading = false
            self.history[table].is_data_fetched = true
            self.history[table].dialog = false
            self.dialog = false
          })
    },
    fetchUserData() {
      let self = this

      self.$store.state.loading = true

      let id = self.userId

      let url = `${self.$store.state.server_url}/export/stat/${id}`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.reserved = response.data.stat.reserved
            self.blacklisted = response.data.stat.blacklisted
            self.user_data = response.data.user_data
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    }
  },

  computed: {
    userId(){
      let id = ''

      if(this.$route.params.id !== undefined){
        id = this.$route.params.id
      }

      return id
    },
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    isExportDisable() {
      return this.dates.length < 2 || new Date(this.dates[0]) > new Date(this.dates[1])
    }
  },

  created() {
    this.fetchUserData()
  }
}
</script>

<style scoped>

</style>