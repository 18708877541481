<template>
  <v-app id="inspire">
    <v-main>
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="8"
              md="3"
          >
            <v-card class="elevation-12">
              <v-img
                  src="https://cdn.vuetifyjs.com/images/cards/sunshine.jpg"
                  height="200px"
              ></v-img>
              <v-card-title>
                Login Form
              </v-card-title>
              <v-card-text>
                <v-form>
                  <v-text-field
                      label="Login"
                      name="login"
                      v-model="email"
                      prepend-inner-icon="mdi-account"
                      type="text"
                      solo
                  ></v-text-field>

                  <v-text-field
                      id="password"
                      label="Password"
                      name="password"
                      v-model="password"
                      prepend-inner-icon="mdi-lock"
                      type="password"
                      solo
                  ></v-text-field>
                </v-form>
                <v-alert
                    text
                    dense
                    dismissible
                    border="left"
                    type="error"
                    v-if="error_message"
                >
                  {{ error_message }}
                </v-alert>
              </v-card-text>
              <v-card-actions>
                <v-btn
                    color="primary"
                    class="ma-2"
                    @click="login"
                    :loading="loading"
                    text
                >Login
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "LoginFormIndex",
  props: {
    source: String,
  },
  data() {
    return {
      error_message: undefined,
      snackbar: false,
      loading: false,
      email: '',
      password: '',
    }
  },
  computed: {
    message() {
      return this.$store.state.auth.response.msg
    }
  },
  methods: {
    login() {
      let self = this;

      self.loading = true;

      self.$store.dispatch('login', {
        email: self.email,
        password: self.password,
        then() {
          let redirect_url = self.$store.state.redirect_to_after_login

          if (redirect_url !== '') {
            self.$store.state.redirect_to_after_login = ''
            self.$router.push(redirect_url)
          } else {
            self.$router.push('/')
          }
        },
        catch() {
          self.snackbar = true
          self.loading = false
          self.error_message = self.$store.state.auth.response.msg
        }
      })
    }
  },
}
</script>