<template>
  <div class="d-inline-block">
    <v-btn
        v-if="type === 'default'"
        outlined
        color="info"
        rounded
        @click="dialog = true"
    >Export <v-icon size="20" class="ml-2">mdi-database-export</v-icon></v-btn>
    <v-fab-transition v-else>
      <v-btn
          color="pink"
          dark
          fixed
          bottom
          right
          fab
          @click="dialog = true"
      >
        <v-icon>mdi-database-export</v-icon>
      </v-btn>
    </v-fab-transition>
    <v-dialog
        v-model="dialog"
        max-width="300"
    >
      <v-card>
        <v-card-title></v-card-title>
        <v-card-text>
          <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              solo
              label="Search"
          ></v-text-field>
          <v-dialog
              ref="dialog"
              v-model="dialog_dates"
              persistent
              width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="dateRangeText"
                  solo
                  label="Date range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="dates"
                range
                scrollable
            >
              <v-spacer></v-spacer>
              <v-btn
                  text
                  color="primary"
                  @click="dialog_dates = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-card-text>
        <v-card-actions>
          <v-btn
              text
              color="primary"
              @click="generateExportHash"
              :disabled="isExportDisable"
          >
            Export
          </v-btn>
          <v-btn
              text
              @click="dialog = false"
          >Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Export",

  props: ['type', 'user'],

  data: () => ({
    search: '',
    dialog_dates: false,
    dialog: false,
    dates: []
  }),

  methods: {
    generateExportHash() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/export/generation`

      let config = {
        params: {
          date_from: self.dates[0],
          date_to: self.dates[1],
          search: self.search,
          user: self.user
        },
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            window.open(`${self.$store.state.server_url}/export/download/${response.data.hash}`, '_blank')
          })
          .finally(() => {
            self.dialog = false
            self.$store.state.loading = false
          })
    },
  },

  computed: {
    dateRangeText() {
      return this.dates.join(' ~ ')
    },
    isExportDisable() {
      return this.dates.length < 2 || new Date(this.dates[0]) > new Date(this.dates[1])
    }
  },
}
</script>

<style scoped>

</style>