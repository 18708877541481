<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card
              class="mx-auto my-12"
              max-width="374"
              elevation="15"
          >
            <v-card-title>{{ user_data.name }}</v-card-title>
            <v-card-text>
              <p>{{ user_data.email }}</p>
              <v-alert
                  text
                  type="success"
              >
                Reserved: {{ reserved }}
              </v-alert>
              <v-alert
                  text
                  type="error"
              >
                Blacklisted: {{ blacklisted }}
              </v-alert>
              <div class="text-center">
                <Export type="default"></Export>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Export from "@/components/Common/Export"
import axios from "axios";

export default {
  name: "Index",
  data: () => ({
    reserved: 0,
    blacklisted: 0,
    user_data: {}
  }),
  components: {Export},
  methods: {
    fetchUserData() {
      let self = this

      self.$store.state.loading = true

      let url = `${self.$store.state.server_url}/export/stat`

      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': self.$store.state.auth.token
        }
      }

      axios.get(url, config)
          .catch((err) => {
            if (err.response.status === 401) {
              self.$store.dispatch('logout', function () {
                self.$router.push('/login');
              })
            }
          })
          .then((response) => {
            self.reserved = response.data.stat.reserved
            self.blacklisted = response.data.stat.blacklisted
            self.user_data = response.data.user_data
          })
          .finally(() => {
            self.$store.state.loading = false
          })
    }
  },
  created() {
    this.fetchUserData()
  }
}
</script>

<style scoped>

</style>